import React from 'react';
import Link from "gatsby-link";
import {mediaBaseURL} from "../../../utils/constant";

function Solutionsidemenu({data}) {
    return (
        <ul className="side_menu">
            {data && data ? data.map((value, i) => (
                <>
                    {value.tabs && value.tabs ? value.tabs.map((item, i) => (
                        <li key={i}>
                            <Link to={item.tab_link}>
                                <img
                                    src={`${mediaBaseURL}${item.icon}`}
                                    alt={item.title}/>
                                <h5>{item.title}</h5>
                                <span>{item.tab_description}</span>
                            </Link>
                        </li>
                    )) : ''}
                </>
            )) : ''}
        </ul>
    );
}

export default Solutionsidemenu;
